import axios from "axios";
import { DiscoverySession } from "./discovery-session";

const DEFAULT_GENERIC_PROVIDER_METHOD = "post";

function axiosRequestProvider(apiKey, body, href, options, method: any, session, onProgress?: any) {
    return new Promise((resolve, reject) => {
        axios.request({
            ...options,
            url: href,
            data: body,
            method: method,
            withCredentials: false,
            headers: {
                "Authorization": 'Bearer ' + apiKey
            },
            onUploadProgress: (progressEvent) => {
                if (onProgress) {
                    if (progressEvent.lengthComputable) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onProgress(percentCompleted);
                    }
                }
            },
            onDownloadProgress: (progressEvent) => {
                console.log(progressEvent);
                if (onProgress) {
                    if (progressEvent.lengthComputable) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onProgress(percentCompleted);
                    }
                }
            }
        }).then((r) => {
            resolve(r.data);
        }).catch((error) => {
            reject(error);
        })
    });
}

function paramsMiddleware(session, params) {
    return {
        ...params,
        sessionID: session.sessionID
    }
}

function bodyMiddleware(session, body) {
    return {
        ...body,
        sessionID: session.sessionID
    }
}

async function provider(session: DiscoverySession, path: string, body: any, options: any, method: any, onProgress?: any) {
    try {
        // generically upercase the method
        method = method.toUpperCase();

        // get our modular api key
        const apiKey = session.apiToken;

        // construct a full url (technically hard coded to HTTP)
        let baseURL = process.env.NEXT_PUBLIC_LINX_URL;
        let href = `${baseURL}${path}`;

        // run through our middleware and inject anything in the body that should always be there
        body = bodyMiddleware(session, body);

        // check for params to run through the middleware
        if (options && options.params) {
            options.params = paramsMiddleware(session, options.params);
        }

        // pick a provider, all providers should have this interface
        return await axiosRequestProvider(apiKey, body, href, options, method, session, onProgress);
    }
    catch (e) {
        console.error(e);
    }
}

export async function api(path: string, body: any, method: string) {

}

function pathMiddleware(session, path) {
    if (path.indexOf("/") !== 0) {
        return `/${path}`
    }

    return path;
}

// These are 2 entry points to make API calls, pass the userID to get drop-in authentication
// the other version is just a callback version
export async function protectedApiCall(session: DiscoverySession, path: string, body: any, options: any, method: any, onProgress?: any) {
    if (method === undefined) method = 'get';

    path = pathMiddleware(session, path);

    if (method === undefined) method = DEFAULT_GENERIC_PROVIDER_METHOD;
    return await provider(session, path, body, options, method, onProgress);
}

export function protectedApiCallCallback(session: DiscoverySession, path: string, body: any, options: any, onDone: any, method: any, onProgress?: any) {
    if (method === undefined) method = 'get';

    path = pathMiddleware(session, path);

    if (method === undefined) method = DEFAULT_GENERIC_PROVIDER_METHOD;
    provider(session, path, body, options, method, onProgress).then((r) => {
        onDone(r);
    })
}

// function keycloakApiKeyProvider(userID) {
//     return getStoredApiKey(userID);
// }

// // same modular pattern for the requests providers.  allows us to simply swap out the authentication provider
// async function apiKeyProvider(userID) {
//     return keycloakApiKeyProvider(userID);
// }

// function getStoredApiKey(userID: string) {
//     if (typeof window !== "undefined") {
//         return window.sessionStorage.getItem("iw-last-apiKey-" + userID);
//     }
//     return null;
// }

// export function storeApiKey(userID: string, latestApiKey: string) {
//     if (typeof window !== "undefined") {
//         window.sessionStorage.setItem("iw-last-apiKey-" + userID, latestApiKey);
//     }
// }
