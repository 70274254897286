import { atomWithLocalStorage } from './comments';
import axios from 'axios';
import moment from 'moment';
import { resolve } from 'styled-jsx/css';
import { protectedApiCall } from './network';
import { sortEntries } from '../../util/entries';
import { clone } from 'lodash';
import { atom } from 'jotai';
import { Chip } from '@mui/material';

const FIELDS_TO_NOT_PERSIST = ['canvasSliderValue', 'newInterviewOpen'];

// generate working interfaces for everything missing in BASEStateInterface
interface ITeam {
    id: string;
    name: string;
    role: string;
    permissions: string[];
    org: string;
    groupID: string;
}

interface ICohort {
    id: string;
    name: string;
    team: string;
    members: string[];
    createdAt: string;
    updatedAt: string;
}

interface IFolder {
    id: string;
    name: string;
    team: string;
    createdAt: string;
    updatedAt: string;
    parent: string;
}


interface BASEStateInterface {
    user: User;
    team: ITeam;
    cohort: ICohort;
    cohorts: ICohort[];
    teams: ITeam[];
    folders: IFolder[];
    org: any;
    hierarchy: any;
    activity: api.Activity[];
    transcriptPlayNextFrame: any;
    comments: any;
    playbackRate: number;
    BASE: any;
    canvas: any;
    roles: any;
    assemblies: any;
    findingLinks: any;
    templates: any;
    media: any;
    discoveryNavigationOpen: boolean;
    artifactCurrentEditor: any;
    uppyFiles: any;
    canvas_view: any;
    customFindingTypes: any;
    selectedFinding: any;
    readyToLogout: boolean;
    pathRequest: string;
    uploadReservation: string;
    interviewEditMode: boolean;
    editingInterview: any;
    selectedInterview: string;
    currentTimeStamp: number;
    newInterviewOpen: boolean;
    showingCanvasNotes: boolean;
    viewTemplatesOpen: boolean;
    discoveryFocusMode: boolean;
    flatHierarchy: any;
    selectedArtifactType: string;
    selectedVideo: string;
    slectedVideoWikiSectionID: string;
    videoPopOut: boolean;
    primaryClickMenuSelection: string;
    insightSelection: any;
    app: any;
    loading: boolean;
    canvasCommentTarget: string;
    discoveryViewMode: string;
    links: any;
    canvasInsightTarget: string;
    canvasLinkTarget: string;
    findings: any;
    orgTreeSticky: boolean;
    canvasCanvasOptionsOpen: boolean;
    canvasCanvasFiltersOpen: boolean;
    notificationSettingsOpen: boolean;
    unread: any;
    selectedGroup: string;
    snackbarQueue: any;
    note: any;
    transcript: any;
    members: any;
    findingTypes: any;
    notifications: any;
    canvasSliderValue: number;
    history: any;
    wiki: any;
    wikiExpansion: any;
    favoritedTagsVisibility: boolean;
    ui: any;
}


export const INITIAL_BASE_ATOM_VALUE: BASEStateInterface = {
    ui: {},
    //@ts-ignore
    user: {},
    //@ts-ignore
    team: {},
    //@ts-ignore
    cohort: {},
    activity: [],
    notifications: [],
    //@ts-ignore
    cohorts: {},
    artifactCurrentEditor: {},
    unread: {},
    primaryClickMenuSelection: undefined,
    discoveryNavigationOpen: false,
    transcriptPlayNextFrame: false,
    interviewEditMode: false,
    playbackRate: 1.0,
    currentTimeStamp: 0,
    uppyFiles: [],
    selectedFinding: undefined,
    //@ts-ignore
    teams: {},
    wikiExpansion: {},
    canvas_view: {
        toggle_orientation: false,
        color_view: false,
        comment_view: false,
        insight_view: false,
        hypo_view: false
    },
    note: {},
    comments: [],
    links: [],
    transcript: {},
    findingLinks: [],
    templates: [],
    snackbarQueue: [],
    assemblies: [],
    discoveryFocusMode: false,
    uploadReservation: undefined,
    showingCanvasNotes: false,
    selectedVideo: "",
    slectedVideoWikiSectionID: "",
    videoPopOut: false,
    newInterviewOpen: false,
    readyToLogout: false,
    viewTemplatesOpen: false,
    discoveryViewMode: 'draft',
    selectedArtifactType: 'notes',
    media: {},
    org: {},
    //@ts-ignore
    folders: {},
    canvas: {},
    BASE: {},
    insightSelection: undefined,
    members: [],
    wiki: {},
    editingInterview: false,
    findings: {},
    selectedInterview: undefined,
    selectedGroup: undefined,
    orgTreeSticky: true,
    canvasCommentTarget: undefined,
    canvasInsightTarget: undefined,
    canvasLinkTarget: undefined,
    canvasSliderValue: -1,
    canvasCanvasOptionsOpen: false,
    canvasCanvasFiltersOpen: false,
    notificationSettingsOpen: false,
    hierarchy: [],
    flatHierarchy: [],
    history: {},
    roles: [],
    findingTypes: [],
    pathRequest: "",
    favoritedTagsVisibility: true,
    app: {
        app: {
            chatOpen: false,
            notificationSettingsOpen: false,
            notificationOpen: false,
            orgTreeOpen: true,
            selectedData: { period: 1 },
            invalidToken: false,
            validLicense: true,
            readyToLogout: false,
            validLicenseExpireDate: 'none',
            isFetchingLicense: false,
            currentArtifactTab: 0,
            isBeaconLoaded: false,
            presentationWeek: 0,
            presentationGroup: undefined,
            presentationRefreshFlag: false,
            isFetching: false,
            unreadNotifications: 0,
            userEditLastMessageFlag: 0,
            toastQueue: []
        },
        support: {
            suggestions: [{ path: "/canvas", role: "*", article: "" }]
        },
        canvas: {
            orientation: 'flex',
            showLinkedFindingBadges: true,
            showLinkedHypothesisBadges: false,
            showAllComments: false,
            showLinksByColor: false,
            nubMap: {}
        },
        discovery: {
            interviewList: true,
            transcriptAutoplay: true,
            isFetchingExports: false,
            isFetchingNotes: false,
            isFetchingTranscript: false,
            showConflictPrompt: false,
            conflictPromptUsernameCache: '',
            isSavingArtifactText: false
        }
    },
    loading: true,
}

export const baseAtom = atom(INITIAL_BASE_ATOM_VALUE);
//export const baseAtom = atomWithLocalStorage('BASE', INITIAL_BASE_ATOM_VALUE, FIELDS_TO_NOT_PERSIST);

// export function BASEActionSetFilter1(setBASEState: any, target: boolean) {
//     setBASEState((BASE) => {
//         let c = {...BASE}

//         c['BASE_filter_showInvalidated'] = target;

//         return c;
//     });
// }

export function getArtifactByID(base: any, artifactID: string) {
    let match = undefined;

    for (let i = 0; i < base.artifacts.length; i++) {
        if (base.artifacts[i].artifactID === artifactID) {
            match = base.artifacts[i];
            break;
        }
    }

    return match;
}

const DEFAULT_STATE = false;
export function getUIStateIsOptionSelected(base: any, option: string) {
    try {
        if (base === undefined || base.ui === undefined) return DEFAULT_STATE;

        const result = base.ui[option];

        if (result === true || result === false) {
            return result;
        }
    }
    catch (e) {
        console.log(e);
    }

    return DEFAULT_STATE;
}

export const handleWikiChanges = ({discoverySession, wikiID, wikiSections, onSuccess, onFailure}) => {
    protectedApiCall(
        discoverySession,
        "state/wiki",
        {
            wikiID,
            wikiSections,
        },
        {},
        "put"
    )
    .then((res) => {
        onSuccess(res);
    })
    .catch((error) => {
        onFailure(error);
    });
};

export const updateWikiSection = ({ set, discoverySession, wiki, modifiedSection, onSuccess, onFailure }) => {
    const wikiID = wiki.wikiID;
    const wikiSections = wiki.sections.map(section => section.sectionID === modifiedSection.sectionID ? modifiedSection : section);

    handleWikiChanges({
        discoverySession,
        wikiID,
        wikiSections,
        onSuccess: (res) => {
            set((baseState) => ({
                ...baseState,
                wiki: baseState.wiki.map((wikiItem) =>
                    wikiItem.wikiID === wikiID
                        ? { ...wikiItem, sections: wikiSections }
                        : wikiItem
                ),
            }));

            onSuccess(res);
        },
        onFailure,
    });
}

export const reorderWikiSection = ({
	set,
	discoverySession,
	wiki,
	reorderedSections,
	onSuccess,
	onFailure,
}) => {
	const wikiID = wiki.wikiID;
	const wikiSections = reorderedSections;

	handleWikiChanges({
		discoverySession,
		wikiID,
		wikiSections,
		onSuccess: (res) => {
			set((baseState) => ({
				...baseState,
				wiki: baseState.wiki.map((wikiItem) =>
					wikiItem.wikiID === wikiID
						? { ...wikiItem, sections: wikiSections }
						: wikiItem
				),
			}));

			onSuccess(res);
		},
		onFailure,
	});
};

export const deleteWikiSection = ({
	set,
	discoverySession,
	wiki,
	deletedSection,
	onSuccess,
	onFailure,
}) => {
	const wikiID = wiki.wikiID;
	const wikiSections = wiki.sections.filter(
		(section) => section.sectionID !== deletedSection.sectionID
	);

	handleWikiChanges({
		discoverySession,
		wikiID,
		wikiSections,
		onSuccess: (res) => {
			set((baseState) => ({
				...baseState,
				wiki: baseState.wiki.map((wikiItem) =>
					wikiItem.wikiID === wikiID
						? { ...wikiItem, sections: wikiSections }
						: wikiItem
				),
			}));

			onSuccess(res);
		},
		onFailure,
	});
};

export const addWikiSection = ({
	set,
	discoverySession,
	wiki,
	newSectionName,
	onSuccess,
	onFailure,
}) => {
	const wikiID = wiki.wikiID;
	const newSectionScaffolding = {
		sectionID: crypto.randomUUID(),
		wikiID: wikiID,
		sortOrder: wiki.sections.length + 1,
		name: newSectionName,
		linkSet: [],
		created: new Date().toUTCString(),
	};
	const wikiSections = [...wiki.sections, newSectionScaffolding];

	handleWikiChanges({
		discoverySession,
		wikiID,
		wikiSections,
		onSuccess: (res) => {
			set((baseState) => ({
				...baseState,
				wiki: baseState.wiki.map((wikiItem) =>
					wikiItem.wikiID === wikiID
						? { ...wikiItem, sections: wikiSections }
						: wikiItem
				),
			}));

			onSuccess(newSectionScaffolding);
		},
		onFailure,
	});
}

export function getWikisForCohort(base: any) {
    let result = [];

    if (base.wiki) {
        for (let i = 0; i < base.wiki.length; i++) {
            if (base.wiki[i].groupID === base.cohort.groupID) {
                result.push(base.wiki[i])
            }
        }
    }

    return result;
}

export function getWikisForTeam(base: any) {
    let result = [];

    if (base.wiki) {
        for (let i = 0; i < base.wiki.length; i++) {
            if (base.wiki[i].groupID === base.team.groupID) {
                result.push(base.wiki[i])
            }
        }
    }

    return result;
}

// if target is undefined it's a toggle behaviour
export function setUIStateIsOptionSelected(set: any, base: any, option: string, target?: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        if (newBaseState.ui === undefined || newBaseState.ui === null) {
            newBaseState.ui = {};
        }

        if (target === undefined) {
            target = !getUIStateIsOptionSelected(base, option);
        }

        newBaseState.ui[option] = target;

        return newBaseState;
    });
}

export function getArtifactSessionOwner(base: any, artifactID: string) {
    if (base === undefined || base.artifactCurrentEditor === undefined) {
        return undefined;
    }

    return base.artifactCurrentEditor[artifactID];
}

export function setArtifactSessionOwner(set: any, artifactID: string, userID: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.artifactCurrentEditor[artifactID] = userID;

        return newBaseState;
    });
}

export function setNotificationSettingsOpen(set: any, target: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.notificationSettingsOpen = target;

        return newBaseState;
    });
}

export function doesInterviewNeedAudio(base: any, interviewID: string) {
    try {
        if (base.media[interviewID].vultrKey !== undefined) {
            if (base.media[interviewID].vultrKey !== null) {
                return false;
            }
        }
    }
    catch (e) {
        return true;
    }

    return true;
}

export function getAppState(base: any) {
    if (base.app !== undefined) {
        if (base.app !== undefined) {
            return base.app;
        }
    }

    return {
        app: {
            chatOpen: false,
            notificationSettingsOpen: false,
            notificationOpen: false,
            orgTreeOpen: true,
            selectedData: { period: 1 },
            invalidToken: false,
            validLicense: true,
            readyToLogout: false,
            validLicenseExpireDate: 'none',
            isFetchingLicense: false,
            currentArtifactTab: 0,
            isBeaconLoaded: false,
            presentationWeek: 0,
            presentationGroup: undefined,
            presentationRefreshFlag: false,
            isFetching: false,
            unreadNotifications: 0,
            userEditLastMessageFlag: 0,
            toastQueue: []
        },
        support: {
            suggestions: [{ path: "/canvas", role: "*", article: "" }]
        },
        canvas: {
            orientation: 'flex',
            showLinkedFindingBadges: true,
            showLinkedHypothesisBadges: false,
            showAllComments: false,
            showLinksByColor: false,
            nubMap: {}
        },
        discovery: {
            interviewList: true,
            transcriptAutoplay: true,
            isFetchingExports: false,
            isFetchingNotes: false,
            isFetchingTranscript: false,
            showConflictPrompt: false,
            conflictPromptUsernameCache: '',
            isSavingArtifactText: false
        }
    }
}

export function calculateCanvasActivity(base: any) {
    // generate a sub-set of the normal activity feed for the canvas slider
    let baseActivity = base.activity;

    if (baseActivity === undefined) {
        baseActivity = [];
    }

    let canvasActivity = [];

    let whiteListedActivityType = ['HypothesisLink', 'Hypothesis', 'EntryContentChange', 'HypothesisStateChange', 'Finding']

    for (let i = 0; i < baseActivity.length; i++) {
        const {
            name
        } = baseActivity[i];

        if (whiteListedActivityType.includes(name)) {
            canvasActivity.push(baseActivity[i]);
        }
    }

    return canvasActivity;
}

export function params_getSlider(base: any) {
    const a = calculateCanvasActivity(base);

    const max = a ? a.length : -1;

    let slider = base.canvasSliderValue;

    if (slider < 0) {
        if (a && a.length === 0) {
            slider = max;
        }
    }

    // prevent run-away slider values
    if (slider > max) {
        slider = max;
    }

    return {
        canvasSliderMax: max,
        canvasSliderValue: slider,
        isMax: (base.canvasSliderValue === -1) ? true : max === slider
    }
}

export function setCanvasPeriodValue(set: any, target: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.canvas.selectedWeek = target;

        return newBaseState;
    });
}

export function setCanvasSliderValue(set: any, target: number) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.canvasSliderValue = target;

        return newBaseState;
    });
}

export function showUploadModal(base: any, interviewID: string) {
    if (doesInterviewNeedAudio(base, interviewID) === false) {
        return false;
    }

    return base.selectedArtifactType === "transcription";
}

export function setNewInterviewOpen(set: any, target: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.newInterviewOpen = target;

        return newBaseState;
    });
}

export function setReadyToLogout(set: any, target: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.readyToLogout = target;

        return newBaseState;
    });
}

export function toggleSticky(set: any, target: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.orgTreeSticky = target;

        return newBaseState;
    });
}

export function setInsightSelection(set: any, target: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.insightSelection = target;
        //newBaseState.selectedFinding = undefined;

        return newBaseState;
    });
}

export function setSelectedArtifactType(set: any, target: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.selectedArtifactType = target;

        return newBaseState;
    });
}

export function addSnackbarRequest(content: any, title: string, set: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        // if this fails, simply dump the attempt
        try {
            // newBaseState.snackbarQueue.push({ content, title, date: new Date() });
        }
        catch (e) {
            return _base;
        }

        return newBaseState;
    });
}

export function setCanvasOpenOptions(set: any, target: string, desiredValue: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        if (target === "filters") {
            if (desiredValue === true) {
                newBaseState.canvasCanvasOptionsOpen = !desiredValue;
            }
            newBaseState.canvasCanvasFiltersOpen = desiredValue;
        }
        else {
            if (desiredValue === true) {
                newBaseState.canvasCanvasFiltersOpen = !desiredValue;
            }
            newBaseState.canvasCanvasOptionsOpen = desiredValue;
        }

        return newBaseState;
    });
}

export function getTopOfSnackbarQueue(set: any, cb: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        if (newBaseState.snackbarQueue.length > 0) {
            const firstItem = newBaseState.snackbarQueue[newBaseState.snackbarQueue.length - 1];

            newBaseState.snackbarQueue = newBaseState.snackbarQueue.slice(0, newBaseState.snackbarQueue.length - 1);

            cb(firstItem);

            return newBaseState;
        }
        else {

            cb(undefined);

            return newBaseState;
        }
    })
}

export function deleteEntry(set: any, entryID: string) {
    set((_base) => {


        let newBaseState = {
            ..._base
        }

        let newCanvas = {
            ..._base.canvas
        }

        for (let i = 0; i < newCanvas.categories.length; i++) {
            let newE = [];
            for (let e = 0; e < newCanvas.categories[i].entries.length; e++) {
                if (newCanvas.categories[i].entries[e].entryID !== entryID) {
                    newE.push(newCanvas.categories[i].entries[e]);
                }
            }
            newCanvas.categories[i].entries = newE;
        }

        newBaseState.canvas = newCanvas;

        return newBaseState;
    });
}

export function getFindingByID(base: any, findingID: string) {
    // if this gets lagy we can optimize it here, so move each
    // use of this behaviour to this entry point
    let match = undefined;

    try {
        for (let i = 0; i < base.findings.length; i++) {
            if (base.findings[i].findingID === findingID) {
                match = base.findings[i];
            }
        }
    } catch (e) { }

    return match;
}

export function getEntryById(base: any, entryID: string) {
    // if this gets lagy we can optimize it here, so move each
    // use of this behaviour to this entry point
    let match = {};

    try {
        for (let i = 0; i < base.canvas.categories.length; i++) {
            for (let e = 0; e < base.canvas.categories[i].entries.length; e++) {
                if (base.canvas.categories[i].entries[e].entryID === entryID) {
                    match = base.canvas.categories[i].entries[e];
                }
            }
        }
    } catch (e) { }

    //@ts-ignore
    if (match.categoryID !== undefined) {
        //@ts-ignore
        match.category = getCategoryById(base, match.categoryID);
    }

    return match;
}

export function getCategoryById(base: any, categoryID: string) {
    // if this gets lagy we can optimize it here, so move each
    // use of this behaviour to this entry point
    let match = {};

    try {
        for (let i = 0; i < base.canvas.categories.length; i++) {
            if (base.canvas.categories[i].id === categoryID) {
                match = base.canvas.categories[i];
            }
        }
    } catch (e) { }

    return match;
}

export function softSelect(set: any, target: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState = calculateSelectedGroup(newBaseState, target);

        return newBaseState;
    });
}

export function silentlyReloadNoteData(set: any, target: string) {

}

export function setIsInterviewInEditMode(set: any, target: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.interviewEditMode = target;

        return newBaseState;
    });
}

export function selectEditingInterview(set: any, target: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.editingInterview = target;

        return newBaseState;
    });
}

export function selectInterviewWithInsight(set: any, target: string, insightTarget: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        try {
            //@ts-ignore
            window.__lastInsightSelection = undefined;
        } catch (e) { }

        newBaseState.insightSelection = undefined;
        newBaseState.selectedFinding = insightTarget;
        newBaseState.selectedInterview = target;
        newBaseState.interviewEditMode = false;

        return newBaseState;
    });
}

export function selectInterview(set: any, target: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        try {
            //@ts-ignore
            window.__lastInsightSelection = undefined;
        } catch (e) { }

        newBaseState.insightSelection = undefined;
        newBaseState.selectedInterview = target;
        newBaseState.interviewEditMode = false;
        newBaseState.selectedArtifactType = 'notes';

        return newBaseState;
    });
}

export function selectCanvasInsight(set: any, target: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.canvasInsightTarget = target;

        return newBaseState;
    });
}

export function selectCanvasLink(set: any, target: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.canvasLinkTarget = target;

        return newBaseState;
    });
}

export function selectCanvasComment(set: any, target: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.canvasCommentTarget = target;

        return newBaseState;
    });
}

export function setDataURLSoft(set: any, interviewID: string, target: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        for (let i = 0; i < newBaseState.artifacts.length; i++) {
            const artifact = newBaseState.artifacts[i];

            if (artifact.interviewID === interviewID) {
                if (artifact.contentType.includes('text')) {
                    newBaseState.artifacts[i].dataURL = target;
                    newBaseState.artifacts[i].vultrKey = target;
                }
            }
        }

        newBaseState.note[interviewID].dataURL = target;
        newBaseState.note[interviewID].vultrKey = target;

        return newBaseState;
    });
}

export function toggleCanvasViewOption(set: any, accessor: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.canvas_view[accessor] = !(newBaseState.canvas_view[accessor]);

        return newBaseState;
    });
}

export function refreshDiscoveryViewMode(set: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.selectedInterview = getFirstInterview(newBaseState.interviews, newBaseState.discoveryViewMode);

        return newBaseState;
    });
}

export function setDiscoveryViewMode(set: any, request: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.discoveryViewMode = request;

        newBaseState.selectedInterview = getFirstInterview(newBaseState.interviews, request);

        return newBaseState;
    });
}

export function getViewMode(base) {
    const mode = base.discoveryViewMode;

    if (mode === 'draft') {
        return mode;
    }

    let foundAny = false;
    for (let i = 0; i < base.interviews.length; i++) {
        const ii = base.interviews[i];

        if (ii.status === base.discoveryViewMode) {
            foundAny = true;
        }
    }

    if (foundAny) {
        return base.discoveryViewMode;
    }
    else {
        return 'draft';
    }
}

export function setPlaybackRate(set: any, request: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.playbackRate = request;

        return newBaseState;
    });
}

export function setPrimaryClickMenuSelection(set: any, request: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.primaryClickMenuSelection = request;

        return newBaseState;
    });
}

export function navigateTo(set: any, request: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.pathRequest = request;

        return newBaseState;
    });
}

function appStateCheck(base) {
    // if (base.app === undefined) {
    //     base.app = getAppState(base);
    // }
    return base;
}

export function setChatToggle(set: any, target: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState = appStateCheck(newBaseState);

        newBaseState.app.chatOpen = target;

        return newBaseState;
    });
}

export function setOrgToggle(set: any, target: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.app.orgTreeOpen = target;

        return newBaseState;
    });
}

export function setDiscoveryFocusMode(set: any, target: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.discoveryFocusMode = target;

        return newBaseState;
    });
}

export function setViewTemplatesOpen(set: any, target: boolean) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.viewTemplatesOpen = target;

        return newBaseState;
    });
}

export function toggleVideoPopout(set: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.videoPopOut = !newBaseState.videoPopOut;

        return newBaseState;
    });
}

export function selectVideo(set: any, target: string, wikiSectionID: string) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.selectedVideo = target;
        newBaseState.slectedVideoWikiSectionID = wikiSectionID;

        return newBaseState;
    });
}

// uppyFiles

export function setUppyFiles(set: any, target: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.uppyFiles = target;

        return newBaseState;
    });
}

export function setCurrentTranscriptionForcePlayFlag(set: any, target: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.transcriptPlayNextFrame = target;

        return newBaseState;
    });
}

export function setCurrentTranscriptionTime(set: any, target: number) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        newBaseState.currentTimeStamp = target;

        return newBaseState;
    });
}

export function addEntryToCategory(categoryID: string, entryPayload: any, set: any) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        for (let i = 0; i < newBaseState.canvas.categories.length; i++) {
            if (categoryID === newBaseState.canvas.categories[i].id) {
                let insertMade = false;
                for (let e = 0; e < newBaseState.canvas.categories[i].entries.length; e++) {
                    if (newBaseState.canvas.categories[i].entries[e].id === entryPayload.id) {
                        newBaseState.canvas.categories[i].entries[e] = entryPayload;
                        insertMade = true;
                    }
                }

                if (insertMade === false) {
                    newBaseState.canvas.categories[i].entries.push(entryPayload);
                }
            }
        }

        return newBaseState;
    });
}

export function getBaseHistoryAsAString(base: any) {
    if (base === undefined) {
        return '';
    }

    let buffer = '';

    const keys = Object.keys(base.history);

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        for (let e = 0; e < base.history[key].length; e++) {
            const entry = base.history[key][e];

            buffer += entry.id + entry.entry;
        }
    }

    return buffer;
}

export function getLastExpandedWikiSection(base, wikiID: string) {
    try {
        return base.wikiExpansion[wikiID];
    }
    catch(e) {
        return undefined;
    }
}

export function setLastExpandedWikiSection(set, wikiID: string, sectionID: string) { 
    set((s) => {
        let buffer = {
            ...s
        }

        if (buffer.wikiExpansion === undefined) {
            buffer.wikiExpansion = {
                'empty': true
            };
        }

        buffer.wikiExpansion[wikiID] = sectionID;

        return buffer;
    });
}

export function setLoadingOrg(set, target: boolean) {
    set((s) => {
        let buffer = {
            ...s
        }

        buffer.loading = target;

        return buffer;
    });
}

function storeMessageInChannel(set, channel, message: any) {
    set((s) => {
        let buffer = {
            ...s
        }

        let updateFound = false;

        if (buffer.history[channel] !== undefined) {
            try {
                for (let i = 0; i < buffer.history[channel].length; i++) {
                    const ii = buffer.history[channel][i];

                    if (ii.id === message.id) {
                        buffer.history[channel][i] = message;
                        updateFound = true;
                    }
                }
            }
            catch (e) {
            }
        }

        if (updateFound === false) {
            try {
                buffer.history[channel].push({
                    ...message,
                    chatID: message.id,
                    created: new Date().toUTCString()
                });
            }
            catch (e) {
                console.log(e);
            }
        }

        return buffer;
    });
}

export function isAnyRole(base: any) {
    const keysToCheck = ['isAdmin', 'isCohortAdmin', 'isInstructor', 'isMentor', 'isObserver', 'isSystemAdministrator', 'isTeamMember'];

    let anyMatches = false;

    for (let i = 0; i < keysToCheck.length; i++) {
        if (checkRole(base, keysToCheck[i])) {
            anyMatches = true;
        }
    }

    return anyMatches;
}

export function getChannels(base: any) {
    let user = base.user;
    let group = base.team;
    let cohort = base.cohort;

    let team: TeamState;
    if (group) {
        if (group.type === 'Team') team = group as TeamState;
        if (group.type === 'Cohort') cohort = group as CohortState;
    }

    let items: DropdownItem[] = [];

    let isInstructor = false,
        isCohortAdmin = false,
        isCohortMember = false;

    let isMemberOfTeam = team ? isAnyRole(base) : false;
    let isSystemAdmin = checkRole(base, 'isSystemAdministrator');

    let useInstructorChat = false;
    let useInstructorFeedback = false;

    if (team) {
        useInstructorChat = team.isInstructorChatVisible === 1;
        useInstructorFeedback = team.isInstructorFeedbackVisible === 1;
    }

    // Cohort's policies take precedent if they exist
    if (cohort) {
        useInstructorChat = cohort.isInstructorChatVisible === 1;
        useInstructorFeedback = cohort.isInstructorFeedbackVisible === 1;
    }

    if (user) {
        if (cohort) {
            isInstructor = checkRole(base, 'isInstructor');
            isCohortAdmin = checkRole(base, 'isCohortAdmin');
            isCohortMember = isAnyRole(base);

            if (cohort.isInstructorGeneralVisible === 1) {
                if (isInstructor || isCohortAdmin || isSystemAdmin) items.push({ key: cohort.id + '-IC', value: 'Instructor General' });
            }

            if (cohort.isGeneralVisible === 1) {
                if (isMemberOfTeam || isCohortMember || isSystemAdmin) items.push({ key: cohort.id + '-GC', value: 'General Channel' });
            }
        }

        if (team) {
            if (isMemberOfTeam) {
                if (isInstructor === false) {
                    items.push({ key: team.id + '-TC', value: 'Team Chat' });
                }
            }

            if (useInstructorChat) {
                if (isInstructor || isCohortAdmin || isSystemAdmin) items.push({ key: team.id + '-IC', value: 'Instructor Chat' });
            }

            if (useInstructorFeedback) {
                if (isInstructor || isMemberOfTeam || isSystemAdmin) {
                    items.push({ key: team.id + '-PF', value: 'Instructor Feedback' });
                }
            }

            if (cohort && (isMemberOfTeam || isCohortMember || isSystemAdmin)) {
                items.push({ key: team.id + '-PE', value: 'Peer Feedback' });
            }
        }
    }

    return items;
}

export function postMessageToChannel(session, set, channel, message: any) {
    // store it locally
    storeMessageInChannel(set, channel, message);

    // send off a persistence request
    protectedApiCall(session, "chat", {
        ...message,
        isANotification: true,
        silent: false,
        channelID: channel
    }, {}, 'post').then(() => {
        // handle chat posting error
    });
}

export function loadChannelHistory(set, channel, token) {
    // actually load the history, etc
    axios.get(`${process.env.NEXT_PUBLIC_LINX_URL}/refactor/history`, {
        params: {
            channel: channel
        },
        withCredentials: false,
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then((res) => {
        set((s) => {
            let buffer = {
                ...s
            }

            buffer.history[channel] = res.data;

            return buffer;
        });
    });
}

export function getUnseenNotificationCount(base) {
    let total = 0;

    try {
        for (let i = 0; i < base.notifications.length; i++) {
            if (base.notifications[i].seen === 0) {
                total += 1;
            }
        }
    }
    catch(e) {

    }

    return total;
}

function getNodeById(id, teams, cohorts, folders) {
    if (teams[id] !== undefined) return teams[id];
    if (cohorts[id] !== undefined) return cohorts[id];
    if (folders[id] !== undefined) return folders[id];

    return {}
}

function getRootId(folders) {
    let match = "";
    for (let i = 0; i < folders.length; i += 1) {
        if (folders[i].name === "root") {
            match = folders[i].groupID;
        }
    }
    return match;
}

function prepareHierarchy(hierarchy, teams, cohorts, folders) {
    let _hier = {
        group: {},
        children: []
    };

    const rootID = getRootId(folders);

    _hier.group = folders[rootID];

    for (let i = 0; i < hierarchy.length; i += 1) {

    }

    return _hier;
}

export function getInterviewByInterviewID(base, target) {
    if (base === undefined) return {}

    let match = undefined;

    for (let i = 0; i < base.interviews.length; i += 1) {
        if (base.interviews[i].interviewID === target) {
            match = base.interviews[i];
        }
    }

    return match;
}

function getFirstInterview(interviews, targetStatus) {
    if (interviews === undefined || interviews.length === 0) return {}

    let sorted = interviews.sort((a, b) => {
        return moment(a.created).isBefore(moment(b.created)) ? 1 : -1;
    })

    let interviewID = undefined;

    for (let i = 0; i < sorted.length; i += 1) {
        if (interviewID === undefined) {
            let sStatus = sorted[i].status;

            if (sStatus === undefined || sStatus === null || sStatus === "") sStatus = "draft";

            if (sStatus === targetStatus) {
                interviewID = sorted[i].interviewID;
                return interviewID;
            }
        }
    }

    return interviewID;
}

export function recieveComment(set, comment) {
    set((base) => {
        let buffer = {
            ...base
        }

        comment.id = comment.commentID;

        let match = false;

        for (let i = 0; i < buffer.comments.length; i++) {
            if (buffer.comments[i].commentID === comment.id) {
                buffer.comments[i] = comment;
                match = true;
            }
        }

        if (match === false) {
            buffer.comments.push(comment);
        }

        return buffer;
    });
}

export function getAssemblyFromInterview(base, interviewID) {
    let match = undefined;

    if (base === undefined || base.assemblies === undefined) return undefined;

    for (let i = 0; i < base.assemblies.length; i++) {
        const t = base.assemblies[i];

        if (interviewID !== undefined) {
            if (t.reservation === interviewID || t.interviewID === interviewID) {
                match = t;
            }
        }
    }

    if (match === undefined) return match;

    return match;
}

export function getAssemblyTypeFromInterview(base, interviewID) {
    let match = undefined;

    if (base === undefined || base.assemblies === undefined) return undefined;

    for (let i = 0; i < base.assemblies.length; i++) {
        const t = base.assemblies[i];

        if (interviewID !== undefined) {
            if (t.reservation === interviewID || t.interviewID === interviewID) {
                match = t;
            }
        }
    }

    if (match === undefined) return match;

    return match.type;
}

export function getCustomerSegments(base) {
    let matches = [];

    for (let i = 0; i < base.canvas.categories.length; i++) {
        const cat = base.canvas.categories[i];

        if (cat.name === "Customer Segments") {
            for (let e = 0; e < cat.entries.length; e++) {
                matches.push(cat.entries[e]);
            }
        }
    }

    return matches;
}

export function recieveAssemblie(set, template) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        let found = false;
        for (let i = 0; i < newBaseState.assemblies.length; i++) {
            const t = newBaseState.assemblies[i];

            if (t.assemblyID === template.assemblyID) {
                found = true;
                newBaseState.assemblies[i] = template;
            }
        }

        if (found === false) {
            newBaseState.assemblies.push(template);
        }

        return newBaseState;
    });
}

export function recieveTemplate(set, template) {
    set((_base) => {
        let newBaseState = {
            ..._base
        }

        let found = false;
        for (let i = 0; i < newBaseState.templates.length; i++) {
            const t = newBaseState.templates[i];

            if (t.templateID === template.templateID) {
                found = true;
                newBaseState.templates[i] = template;
            }
        }

        if (found === false) {
            newBaseState.templates.push(template);
        }

        return newBaseState;
    });
}

export function recieveMedia(set, newMedia) {
    set((base) => {
        const { artifactID, interviewID, vultrKey, dataURL } = newMedia;
        let buffer = {
            ...base
        }

        try {
            buffer.media[interviewID] = {
                artifactID,
                interviewID,
                vultrKey,
            };
            buffer.transcript[interviewID] = {
                id: artifactID,
                dataURL,
                contentType: "application/json",
                artifactID,
                interviewID,
                vultrKey,
            };
        }
        catch (e) {
            console.log(e)
        }

        return buffer;
    })
}

export function removeLink(set, entryID, targetEntryID) {
    set((base) => {
        let buffer = {
            ...base
        }

        let b = [];

        for (let i = 0; i < buffer.canvas.linkSet.length; i++) {
            if (buffer.canvas.linkSet[i].entryID !== entryID) {
                if (buffer.canvas.linkSet[i].targetEntryID !== targetEntryID) {
                    b.push(buffer.canvas.linkSet[i]);
                }
            }
            else {
                if (buffer.canvas.linkSet[i].targetEntryID !== targetEntryID) {
                    b.push(buffer.canvas.linkSet[i]);
                }
            }
        }

        buffer.canvas.linkSet = b;

        return buffer;
    })
}

export function setIsDiscoveryNavigationOpen(set, target) {
    set((base) => {
        let buffer = {
            ...base
        }

        buffer.discoveryNavigationOpen = target;

        return buffer;
    })
}


export function setShowCanvasNotes(set, target) {
    set((base) => {
        let buffer = {
            ...base
        }

        buffer.showingCanvasNotes = target;

        return buffer;
    })
}

export function addLInk(set, payload) {
    set((base) => {
        let buffer = {
            ...base
        }

        buffer.canvas.linkSet.push(payload);

        return buffer;
    })
}

export function clearNoteLoadFlag(set, interviewID) {
    set((base) => {
        let buffer = {
            ...base
        }

        buffer.note[interviewID].ffl = false;

        return buffer;
    })
}

export function selectInsight(set, target) {
    set(async (base) => {
        let buffer = {
            ...base
        }

        buffer.selectedFinding = target;

        return buffer;
    })
}

export function flagNoteForReload(set, interviewID) {
    set((base) => {
        let buffer = {
            ...base
        }

        buffer.note[interviewID].ffl = true;

        return buffer;
    })
}

export function coundFindingsInInterview(base, interviewID) {
    let count = 0;

    for (let i = 0; i < base.findings.length; i++) {
        if (base.findings[i].interviewID === interviewID) {
            count += 1;
        }
    }

    return count;
}

export function getIsEntryLinkedToEntry(base, sourceEntry, targetEntry) {
    for (let i = 0; i < base.canvas.linkSet.length; i++) {
        if (base.canvas.linkSet[i].entryID === sourceEntry) {
            if (base.canvas.linkSet[i].targetEntryID === targetEntry) {
                return true;
            }
        }
        if (base.canvas.linkSet[i].targetEntryID === sourceEntry) {
            if (base.canvas.linkSet[i].entryID === targetEntry) {
                return true;
            }
        }

    }

    return false;
}

export function recieveEntryLink(discoverySession, set, base, entry, links) {
    const payload = {
        groupID: base.team.groupID,
        entryID: entry,
        targetEntryID: links.selectedItems.join(","),
    };

    protectedApiCall(discoverySession, '/state/link', payload, {
        method: 'POST',
    }, 'post');

    set((base) => {
        let buffer = {
            ...base
        }

        payload.targetEntryID = links.selectedItems[links.selectedItems.length - 1];

        buffer.canvas.linkSet.push(payload);

        return buffer;
    });
}

export function getDisplayableActivityFeed(base) {
    let buffer = base.activity;

    for (let i = 0; i < buffer.length; i++) {
        const activityEntry = buffer[i];

        let display = {
            title: '',
            subtext: '',
            date: moment(activityEntry.created).format("MMM DD 'YY"),
            chip: <Chip label="-" variant="outlined" />
        }

        if (activityEntry.name === "HypothesisStateChange") {
            display.title = "Hypothesis State Change";
            display.subtext = `${activityEntry.hypothesis} was ${activityEntry.hypothesisState}`
            display.chip = <Chip label="Canvas" variant="outlined" />
        }
        else if (activityEntry.name === "HypothesisLink") {
            display.title = "Hypothesis Linked to " + activityEntry.targetCategoryAbbreviation;
            display.subtext = `${activityEntry.hypothesis} was linked to ${activityEntry.targetHypothesis}`;
            display.chip = <Chip label="Canvas & Insights" variant="outlined" />
        }

        buffer[i].display = display;
    }

    return buffer;
}

function ensureBufferHasLink(buffer, findingID, linkID, entryID, supports, categoryID) {
    let match = false;
    for (let i = 0; i < buffer.findingLinks.length; i++) {
        const f = buffer.findingLinks[i];

        if (f.entryID === entryID && f.findingID === findingID) {
            buffer.findingLinks[i] = {
                ...f,
                created: f.created,
                findingID: findingID,
                entryID: entryID,
                linkID: linkID,
                supports: supports,
                categoryID: categoryID
            }
            match = true;
        }
    }

    if (match === false) {
        buffer.findingLinks.push({
            created: new Date().toUTCString(),
            findingID: findingID,
            entryID: entryID,
            linkID: linkID,
            supports: supports,
            categoryID: categoryID
        })
    }

    return buffer;
}

export function recieveFinding(set, finding, selectIt) {
    set((base) => {
        let buffer = {
            ...base
        }

        for (let j = 0; j < buffer.interviews.length; j++) {
            if (finding.interviewID === buffer.interviews[j].interviewID) {
                finding.interview = buffer.interviews[j];
            }
        }

        finding.id = finding.findingID;

        if (finding.hypothesisLinks === undefined || finding.hypothesisLinks === null) {
            finding.hypothesisLinks = [];

            for (let i = 0; i < buffer.findingLinks.length; i++) {
                if (buffer.findingLinks[i].findingID === finding.id) {
                    finding.hypothesisLinks.push(buffer.findingLinks[i]);
                }
            }
        }

        let match = false;

        for (let i = 0; i < buffer.findings.length; i++) {
            if (buffer.findings[i].id === finding.id) {
                buffer.findings[i] = finding;
                match = true;
            }
        }


        if (match === false) {
            buffer.findings.push(finding);
        }

        // update links in the state as well
        // console.log(finding);

        // remove any existing links for this finding
        let cleanedLinks = [];
        for (let i = 0; i < buffer.findingLinks.length; i++) {
            if (buffer.findingLinks[i].findingID !== finding.id) {
                cleanedLinks.push(buffer.findingLinks[i]);
            }
        }

        buffer.findingLinks = cleanedLinks;

        for (let i = 0; i < finding.hypothesisLinks.length; i++) {
            let entryID = finding.hypothesisLinks[i].entryID;
            let categoryID = finding.hypothesisLinks[i].categoryID;
            let linkID = finding.hypothesisLinks[i].linkID;

            if (entryID === undefined) { entryID = finding.hypothesisLinks[i].hypothesisKey; }
            if (categoryID === undefined) { categoryID = finding.hypothesisLinks[i].category; }
            if (linkID === undefined) { linkID = finding.hypothesisLinks[i].key; }

            buffer = ensureBufferHasLink(buffer, finding.id, linkID, entryID, finding.hypothesisLinks[i].supports, categoryID)
        }

        if (selectIt === true) {
            buffer.selectedFinding = finding;
        }

        return buffer;
    });
}

async function triggerCategories(catSet, groupID, token) {
    // const result = await axios.get(`${process.env.NEXT_PUBLIC_LINX_URL}/categories`, {
    //     params: {
    //         teamID: groupID
    //     },
    //     headers: {
    //         "Authorization": 'Bearer ' + token
    //     }

    // })

    // let final = [result.data];
    // // console.log(result.data);
    // // console.log("!");
    // if (Array.isArray(result.data)) {
    //     final = result.data;
    // }

    // catSet((oldState) => {
    //     return {
    //         categoryData: final
    //     }
    // });
}

export function recieveLinxPayload(set, m) {
    /*
    actualChannel
: 
null
channel
: 
"276c2d37-cdf2-4372-bc88-77b5c61964df-linx"
message
: 
{channel: '276c2d37-cdf2-4372-bc88-77b5c61964df-linx', message: {…}}
publisher
: 
"712c5b9c-3996-4e2e-a30c-217cb660a189"
subscribedChannel
: 
"276c2d37-cdf2-4372-bc88-77b5c61964df-linx"
subscription
: 
undefined
timetoken
: 
"16711229342806522"
*/


    const {
        source,
        type,
        message
    } = m;

    console.log(m);

    const data = message;

    let ours = false;

    try {
        //@ts-ignore
        if (window.__sid === source) {
            ours = true;
        }
    }
    catch (e) {

    }

    // ignore our own
    if (ours) return;

    if (type === 'entry') {
        try {
            addEntryToCategory(data.categoryID, data, set);
        }
        catch (e) {
            console.error(e);
        }
    }
    if (type === 'template') {
        try {
            recieveTemplate(set, data);
        }
        catch (e) {
            console.error(e);
        }
    }
    if (type === 'assemblies') {
        try {
            recieveAssemblie(set, data);
        }
        catch (e) {
            console.error(e);
        }
    }
    if (type === 'insight') {
        try {
            recieveFinding(set, data, false);
        }
        catch (e) { }
    }
    if (type === 'chat') {
        storeMessageInChannel(set, m.channel, data);
    }
    if (type === 'artifactOwner') {
        try {
            setArtifactSessionOwner(set, data.artifactID, data.userID);
        }
        catch (e) {
            console.error(e);
        }
    }
    if (type === 'link') {
        const payload = data.payload;
        const action = data.action;

        if (action === 'add') {
            addLInk(set, payload)
        }
        else {
            removeLink(set, payload.entryID, payload.targetEntryID);
        }
    }
    if (type === 'interview') {
        try {
            const {
                action,
                interviewID,
                noteKey
            } = data;

            if (noteKey !== undefined) {
                recieveInterview(set, data);
            }
            else {
                if (action === "put") {
                    // flagNoteForReload(set, interviewID);
                }
            }
        }
        catch (e) {
            console.error(e);
        }

    }
    if (type === 'comment') {
        try {
            recieveComment(set, data);
        }
        catch (e) {
            console.error(e);
        }
    }
}

export function recieveInterview(set, interview) {
    if (interview.interviewID !== undefined && interview.id === undefined) {
        interview.id = interview.interviewID;
    }
    else if (interview.id !== undefined && interview.interviewID === undefined) {
        interview.interviewID = interview.id;
    }

    set((base) => {
        let buffer = {
            ...base
        }

        let match = false;
        for (let i = 0; i < buffer.interviews.length; i++) {
            if (buffer.interviews[i].interviewID === interview.id) {
                buffer.interviews[i] = {
                    ...buffer.interviews[i],
                    ...interview
                }
                match = true;
            }
        }

        if (match === false) {
            buffer.interviews.push(interview);
        }

        // in this scenario also add a note based on our vultrKey
        if (interview.noteKey !== undefined) {
            let existingNote = buffer.note[interview.interviewID];

            if (existingNote) {
                buffer.note[interview.interviewID] = {
                    ...buffer.note[interview.interviewID],
                    interviewID: interview.id,
                    vultrKey: interview.noteKey,
                    artifactID: interview.noteKey,
                    dataURL: interview.noteKey
                }
            }
            else {
                buffer.note[interview.interviewID] = {
                    interviewID: interview.id,
                    artifactID: interview.noteKey,
                    vultrKey: interview.noteKey,
                    dataURL: interview.noteKey
                }
            }
        }

        return buffer;
    })
}

const formatStatus = (status) => {
    if (status === "draft" || status === "published") {
        return status;
    }
    return "draft";
}

export function handleUpdateInterview(set, setCategoryState, discoverySession, speakerCache, template, _interview, isValid, cb?) {
    const groupID = discoverySession.session.groupID;

    let newInterviewID = undefined;

    if (_interview) {
        newInterviewID = _interview.interviewID ? _interview.interviewID : crypto.randomUUID();
    }
    else {
        newInterviewID = crypto.randomUUID();
    }

    recieveInterview(set, {
        status: 'draft',
        ..._interview,
        interviewID: newInterviewID
    });

    if (cb) {
        cb();
    }

    isValid(true);

    // also send off a copy to preview it loading
    protectedApiCall(discoverySession, 'refactor/interview', {
        ...{
            ..._interview,
            status: formatStatus(_interview ? _interview.status : 'draft'),
            speakers: speakerCache,
            template: template,
            interviewID: newInterviewID,
        },
        groupID: groupID
    }, {}, 'put').then((res) => {
        //@ts-ignore

        // //@ts-ignore
        const interviewID = res.id;

        loadTeamForInterview(set, setCategoryState, groupID, interviewID, discoverySession.apiToken, () => {

        });
    });
}

export function handleNewInterview(set, setCategoryState, discoverySession, speakerCache, template, _interview, isValid, cb?) {
    const groupID = discoverySession.session.groupID;

    let newInterviewID = undefined;

    if (_interview) {
        newInterviewID = _interview.interviewID ? _interview.interviewID : crypto.randomUUID();
    }
    else {
        newInterviewID = crypto.randomUUID();
    }

    recieveInterview(set, {
        status: 'draft',
        ..._interview,
        interviewID: newInterviewID
    });

    if (cb) {
        cb();
    }

    isValid(true);

    // also send off a copy to preview it loading
    protectedApiCall(discoverySession, 'refactor/interview', {
        ...{
            ..._interview,
            status: formatStatus(_interview ? _interview.status : 'draft'),
            speakers: speakerCache,
            template: template,
            interviewID: newInterviewID,
        },
        groupID: groupID
    }, {}, 'post').then((res) => {
        //@ts-ignore

        // //@ts-ignore
        const interviewID = res.id;

        loadTeamForInterview(set, setCategoryState, groupID, interviewID, discoverySession.apiToken, () => {

        });
    });
}

export function loadTeamForInterview(set, catSet, groupID, interviewID, token, cb) {
    triggerCategories(catSet, groupID, token);

    axios.get(`${process.env.NEXT_PUBLIC_LINX_URL}/state/base`, {
        withCredentials: false,
        params: {
            groupID: groupID,
        },
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then((res) => {
        set((BASE) => {
            let resDataWithIDMapping = {
                ...BASE,
                ...res.data.base
            }

            const minter = res.data.minter;

            resDataWithIDMapping.pathRequest = BASE.pathRequest;

            if (resDataWithIDMapping?.pathRequest?.includes("?")) {
                resDataWithIDMapping.pathRequest = "";
            }

            // map our data to how redux handled it
            resDataWithIDMapping.org.id = resDataWithIDMapping.org.orgID;
            resDataWithIDMapping.team.id = resDataWithIDMapping.team.groupID;
            resDataWithIDMapping.team.type = resDataWithIDMapping.team.groupType;
            resDataWithIDMapping.cohort.type = resDataWithIDMapping.cohort.groupType;
            resDataWithIDMapping.team.teamID = resDataWithIDMapping.team.groupID;
            resDataWithIDMapping.cohort.id = resDataWithIDMapping.cohort.groupID;
            resDataWithIDMapping.cohort.teamID = resDataWithIDMapping.cohort.groupID;
            resDataWithIDMapping.org.hierarchy = resDataWithIDMapping.hierarchy;
            resDataWithIDMapping.user.roles = resDataWithIDMapping.roles;
            resDataWithIDMapping.findings = resDataWithIDMapping.insights;

            for (let i = 0; i < resDataWithIDMapping.findings.length; i++) {
                resDataWithIDMapping.findings[i].id = resDataWithIDMapping.findings[i].findingID;
                for (let j = 0; j < resDataWithIDMapping.interviews.length; j++) {
                    if (resDataWithIDMapping.findings[i].interviewID === resDataWithIDMapping.interviews[j].interviewID) {
                        resDataWithIDMapping.findings[i].interview = resDataWithIDMapping.interviews[j];
                    }
                }
            }

            for (let i = 0; i < resDataWithIDMapping.interviews.length; i++) {
                resDataWithIDMapping.interviews[i].interviewee = resDataWithIDMapping.interviews[i].name;
                resDataWithIDMapping.interviews[i].id = resDataWithIDMapping.interviews[i].interviewID;
            }

            if (resDataWithIDMapping.media === undefined) {
                resDataWithIDMapping.media = {};
            }

            if (resDataWithIDMapping.note === undefined) {
                resDataWithIDMapping.note = {};
            }

            if (resDataWithIDMapping.transcript === undefined) {
                resDataWithIDMapping.transcript = {};
            }

            for (let i = 0; i < resDataWithIDMapping.artifacts.length; i++) {
                const artifact = resDataWithIDMapping.artifacts[i];

                if (artifact.contentType.includes('text')) {
                    resDataWithIDMapping.note[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

                if (artifact.contentType.includes('audio')) {
                    resDataWithIDMapping.media[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

                if (artifact.contentType.includes('json')) {
                    resDataWithIDMapping.transcript[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }
            }

            let newState = { ...resDataWithIDMapping }

            newState.app.orgTreeOpen = BASE.app.orgTreeOpen;

            newState.selectedVideo = BASE.selectedVideo;
            newState.slectedVideoWikiSectionID = BASE.slectedVideoWikiSectionID;
            newState.videoPopOut = BASE.videoPopOut;
            newState.selectedInterview = interviewID || newState.selectedInterview;

            newState.canvas_view = BASE.canvas_view;
            newState.discoveryViewMode = 'draft';
            newState.newInterviewOpen = false;

            try { newState.canvasSliderValue = newState.activity.length; } catch (e) { }

            if (newState.canvas) {
                if (newState.canvas.selectedWeek === undefined) {
                    newState.canvas.selectedWeek = 0;
                }
            }

            newState = calculateSelectedGroup(newState, groupID);

            if (cb) {
                cb();
            }

            return newState;
        });
    });
}

function calculateSelectedGroup(base, groupID) {
    let target = groupID;

    if (typeof (target) === "string") {
        // construct this location in the hierarchy in memory
        // isntead of recursively navigating the hierarchy
        let buffer = {
            parentID: "",
            childID: groupID,
            hierarchyID: 0,
            group: {},
            isExpanded: true,
            children: [

            ]
        }

        // find the parent
        for (let i = 0; i < base.flatHierarchy.length; i++) {
            if (base.flatHierarchy[i].childID === groupID) {
                buffer.parentID = base.flatHierarchy[i].parentID;
                buffer.group = base.cohorts[base.flatHierarchy[i].parentID];
            }
        }

        // find the children
        for (let i = 0; i < base.flatHierarchy.length; i++) {
            if (base.flatHierarchy[i].parentID === buffer.parentID) {
                buffer.children.push({
                    group: base.teams[base.flatHierarchy[i].childID]
                });
            }
        }

        base.selectedGroup = buffer;
    }
    else {
        base.selectedGroup = target;
    }

    console.log(`Selected Group: ${base.selectedGroup} from ${target}`);

    return base;
}

export function loadOrg(set, catSet, orgID, session, cb?) {
    set((base) => {
        return {
            ...base,
            loading: true
        }
    });

    protectedApiCall(session, 'state/base', {

    }, {
        params: {
            orgID: orgID,
        },
    }, 'get').then((res) => {
        set((BASE) => {
            let resDataWithIDMapping = {
                ...INITIAL_BASE_ATOM_VALUE,
                //@ts-ignore
                ...res.base
            }

            // in load org mode we delay loading categories, so we know what team to use
            //triggerCategories(catSet, resDataWithIDMapping.team.id, token);

            resDataWithIDMapping.pathRequest = BASE.pathRequest ? BASE.pathRequest : "";

            if (resDataWithIDMapping.pathRequest.includes("?")) {
                resDataWithIDMapping.pathRequest = "";
            }

            // map our data to how redux handled it
            resDataWithIDMapping.org.id = resDataWithIDMapping.org.orgID;
            resDataWithIDMapping.team.id = resDataWithIDMapping.team.groupID;
            resDataWithIDMapping.team.type = resDataWithIDMapping.team.groupType;
            resDataWithIDMapping.cohort.type = resDataWithIDMapping.cohort.groupType;
            resDataWithIDMapping.team.teamID = resDataWithIDMapping.team.groupID;
            resDataWithIDMapping.cohort.id = resDataWithIDMapping.cohort.groupID;
            resDataWithIDMapping.cohort.teamID = resDataWithIDMapping.cohort.groupID;
            resDataWithIDMapping.org.hierarchy = resDataWithIDMapping.hierarchy;
            resDataWithIDMapping.user.roles = resDataWithIDMapping.roles;
            resDataWithIDMapping.findings = resDataWithIDMapping.insights;

            for (let i = 0; i < resDataWithIDMapping.findings.length; i++) {
                resDataWithIDMapping.findings[i].id = resDataWithIDMapping.findings[i].findingID;
                for (let j = 0; j < resDataWithIDMapping.interviews.length; j++) {
                    if (resDataWithIDMapping.findings[i].interviewID === resDataWithIDMapping.interviews[j].interviewID) {
                        resDataWithIDMapping.findings[i].interview = resDataWithIDMapping.interviews[j];
                    }
                }
            }

            for (let i = 0; i < resDataWithIDMapping.interviews.length; i++) {
                resDataWithIDMapping.interviews[i].interviewee = resDataWithIDMapping.interviews[i].name;
                resDataWithIDMapping.interviews[i].id = resDataWithIDMapping.interviews[i].interviewID;
            }

            if (resDataWithIDMapping.media === undefined) {
                resDataWithIDMapping.media = {};
            }

            if (resDataWithIDMapping.note === undefined) {
                resDataWithIDMapping.note = {};
            }

            if (resDataWithIDMapping.transcript === undefined) {
                resDataWithIDMapping.transcript = {};
            }

            for (let i = 0; i < resDataWithIDMapping.artifacts.length; i++) {
                const artifact = resDataWithIDMapping.artifacts[i];

                if (artifact.contentType.includes('text')) {
                    resDataWithIDMapping.note[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

                if (artifact.contentType.includes('audio')) {
                    resDataWithIDMapping.media[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

                if (artifact.contentType.includes('json')) {
                    resDataWithIDMapping.transcript[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

            }

            let newState = { ...resDataWithIDMapping }

            newState.app.orgTreeOpen = BASE.app.orgTreeOpen;
            newState.selectedVideo = BASE.selectedVideo;
            newState.slectedVideoWikiSectionID = BASE.slectedVideoWikiSectionID;
            newState.videoPopOut = BASE.videoPopOut;

            try {
                if (newState.selectedInterview === undefined) {
                    newState.selectedInterview = getFirstInterview(newState.interviews, newState.discoveryViewMode);
                }
            } catch (err) {
                console.error(err);
            }

            newState.readyToLogout = false;
            newState.loading = false;
            newState.canvas_view = INITIAL_BASE_ATOM_VALUE.canvas_view;
            try { newState.canvasSliderValue = newState.activity.length; } catch (e) { }

            if (newState.canvas) {
                if (newState.canvas.selectedWeek === undefined) {
                    newState.canvas.selectedWeek = 0;
                }
            }

            newState = calculateSelectedGroup(newState, resDataWithIDMapping.team.groupID);
            //console.log(newState);

            if (cb) {
                cb();
            }

            return newState;
        });
    });
}

export function loadTeam(set, catSet, groupID, token, cb?) {
    triggerCategories(catSet, groupID, token);

    set((base) => {
        return {
            ...base,
            loading: true
        }
    });

    axios.get(`${process.env.NEXT_PUBLIC_LINX_URL}/state/base`, {
        withCredentials: false,
        params: {
            groupID: groupID,
        },
        headers: {
            "Authorization": 'Bearer ' + token
        }
    }).then((res) => {
        set((BASE) => {
            let resDataWithIDMapping = {
                ...INITIAL_BASE_ATOM_VALUE,
                ...res.data.base
            }

            const minter = res.data.minter;

            resDataWithIDMapping.pathRequest = BASE.pathRequest;

            if (resDataWithIDMapping.pathRequest) {
                if (resDataWithIDMapping.pathRequest.includes("?")) {
                    resDataWithIDMapping.pathRequest = "";
                }
            }
            else {
                resDataWithIDMapping.pathRequest = "";
            }


            // map our data to how redux handled it
            resDataWithIDMapping.org.id = resDataWithIDMapping.org.orgID;
            resDataWithIDMapping.team.id = resDataWithIDMapping.team.groupID;
            resDataWithIDMapping.team.type = resDataWithIDMapping.team.groupType;
            resDataWithIDMapping.cohort.type = resDataWithIDMapping.cohort.groupType;
            resDataWithIDMapping.team.teamID = resDataWithIDMapping.team.groupID;
            resDataWithIDMapping.cohort.id = resDataWithIDMapping.cohort.groupID;
            resDataWithIDMapping.cohort.teamID = resDataWithIDMapping.cohort.groupID;
            resDataWithIDMapping.org.hierarchy = resDataWithIDMapping.hierarchy;
            resDataWithIDMapping.user.roles = resDataWithIDMapping.roles;
            resDataWithIDMapping.findings = resDataWithIDMapping.insights;

            for (let i = 0; i < resDataWithIDMapping.findings.length; i++) {
                resDataWithIDMapping.findings[i].id = resDataWithIDMapping.findings[i].findingID;
                for (let j = 0; j < resDataWithIDMapping.interviews.length; j++) {
                    if (resDataWithIDMapping.findings[i].interviewID === resDataWithIDMapping.interviews[j].interviewID) {
                        resDataWithIDMapping.findings[i].interview = resDataWithIDMapping.interviews[j];
                    }
                }
            }

            for (let i = 0; i < resDataWithIDMapping.interviews.length; i++) {
                resDataWithIDMapping.interviews[i].interviewee = resDataWithIDMapping.interviews[i].name;
                resDataWithIDMapping.interviews[i].id = resDataWithIDMapping.interviews[i].interviewID;
            }

            if (resDataWithIDMapping.media === undefined) {
                resDataWithIDMapping.media = {};
            }

            if (resDataWithIDMapping.note === undefined) {
                resDataWithIDMapping.note = {};
            }

            if (resDataWithIDMapping.transcript === undefined) {
                resDataWithIDMapping.transcript = {};
            }

            for (let i = 0; i < resDataWithIDMapping.artifacts.length; i++) {
                const artifact = resDataWithIDMapping.artifacts[i];

                if (artifact.contentType.includes('text')) {
                    resDataWithIDMapping.note[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

                if (artifact.contentType.includes('audio')) {
                    resDataWithIDMapping.media[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

                if (artifact.contentType.includes('json')) {
                    resDataWithIDMapping.transcript[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }
            }

            let newState = { ...resDataWithIDMapping }

            newState.app.orgTreeOpen = BASE.app.orgTreeOpen;
            newState.selectedVideo = BASE.selectedVideo;
            newState.slectedVideoWikiSectionID = BASE.slectedVideoWikiSectionID;

            try {
                if (newState.selectedInterview === undefined) {
                    newState.selectedInterview = getFirstInterview(newState.interviews, newState.discoveryViewMode);
                }
            } catch (err) {
                console.error(err);
            }

            newState.readyToLogout = false;
            newState.loading = false;
            newState.canvas_view = BASE.canvas_view;
            try { newState.canvasSliderValue = newState.activity.length; } catch (e) { }

            if (newState.canvas) {
                if (newState.canvas.selectedWeek === undefined) {
                    newState.canvas.selectedWeek = 0;
                }
            }

            newState = calculateSelectedGroup(newState, groupID);

            if (cb) {
                cb(newState);
            }

            return newState;
        });
    });
}

export function loadLibraryExternally(session, setBASEState, catSet, token, bridgeCallback, sessionID, orgID?, groupID?) {

    protectedApiCall(session, 'state/base', {}, {
        params: {
            orgID, groupID
        },
    }, 'get', (progress) => {
        // TODO: map this to a loading bar progress value
    }).then((res) => {
        setBASEState((BASE) => {
            let resDataWithIDMapping = {
                ...res.base,
                sessionID
            }

            if (resDataWithIDMapping.discoveryViewMode === undefined) {
                resDataWithIDMapping.discoveryViewMode = 'draft';
            }

            // map our data to how redux handled it
            resDataWithIDMapping.org.id = resDataWithIDMapping.org.orgID;
            resDataWithIDMapping.team.id = resDataWithIDMapping.team.groupID;
            resDataWithIDMapping.team.type = resDataWithIDMapping.team.groupType;
            resDataWithIDMapping.cohort.type = resDataWithIDMapping.cohort.groupType;
            resDataWithIDMapping.team.teamID = resDataWithIDMapping.team.groupID;
            resDataWithIDMapping.cohort.id = resDataWithIDMapping.cohort.groupID;
            resDataWithIDMapping.cohort.teamID = resDataWithIDMapping.cohort.groupID;
            resDataWithIDMapping.org.hierarchy = resDataWithIDMapping.hierarchy;
            resDataWithIDMapping.user.roles = resDataWithIDMapping.roles;
            resDataWithIDMapping.findings = resDataWithIDMapping.insights;

            //triggerCategories(catSet, resDataWithIDMapping.team.id, token);

            for (let i = 0; i < resDataWithIDMapping.findings.length; i++) {
                resDataWithIDMapping.findings[i].id = resDataWithIDMapping.findings[i].findingID;
                for (let j = 0; j < resDataWithIDMapping.interviews.length; j++) {
                    if (resDataWithIDMapping.findings[i].interviewID === resDataWithIDMapping.interviews[j].interviewID) {
                        resDataWithIDMapping.findings[i].interview = resDataWithIDMapping.interviews[j];
                    }
                }
            }

            for (let i = 0; i < resDataWithIDMapping.interviews.length; i++) {
                resDataWithIDMapping.interviews[i].interviewee = resDataWithIDMapping.interviews[i].name;
                resDataWithIDMapping.interviews[i].id = resDataWithIDMapping.interviews[i].interviewID;
            }

            if (resDataWithIDMapping.media === undefined) {
                resDataWithIDMapping.media = {};
            }

            if (resDataWithIDMapping.note === undefined) {
                resDataWithIDMapping.note = {};
            }

            if (resDataWithIDMapping.transcript === undefined) {
                resDataWithIDMapping.transcript = {};
            }

            for (let i = 0; i < resDataWithIDMapping.artifacts.length; i++) {
                const artifact = resDataWithIDMapping.artifacts[i];

                if (artifact.contentType.includes('text')) {
                    resDataWithIDMapping.note[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

                if (artifact.contentType.includes('audio')) {
                    resDataWithIDMapping.media[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }

                if (artifact.contentType.includes('json')) {
                    resDataWithIDMapping.transcript[artifact.interviewID] = {
                        ...artifact,
                        id: artifact.artifactID
                    }
                }
            }

            // make sure that if we're loading an existing state from memory
            // it's from this session
            if (BASE !== undefined) {
                // so, if it doesn't match clear it
                if (BASE.sessionID !== sessionID && sessionID !== undefined) {
                    BASE = {};
                }
            }

            let newState = { ...BASE, ...resDataWithIDMapping }

            try {
                if (newState.selectedInterview === undefined) {
                    newState.selectedInterview = getFirstInterview(newState.interviews, newState.discoveryViewMode);
                }
            } catch (err) {
                console.error(err);
            }

            newState.canvas_view = INITIAL_BASE_ATOM_VALUE.canvas_view;

            newState.readyToLogout = false;
            newState.loading = false;
            try { newState.canvasSliderValue = newState.activity.length; } catch (e) { }

            newState.app = getAppState(newState);

            if (bridgeCallback) {
                bridgeCallback(newState);
            }

            newState = calculateSelectedGroup(newState, resDataWithIDMapping.team.groupID);

            newState.canvas.selectedWeek = 0;

            return newState;
        });
    })
}

export async function getQuoteData(token: any, artifactID: string, selection: any) {
    return new Promise((resolve, reject) => {
        if (selection === undefined || selection === null || selection === '') {
            resolve("");
        }
        else {
            // actually load the history, etc
            let d = `${process.env.NEXT_PUBLIC_LINX_URL}`;
            //d = 'http://localhost:8032'
            axios.get(`${d}/quote/byselection`, {
                params: {
                    selection: JSON.stringify(selection),
                    artifactID: artifactID
                },
                withCredentials: false,
                headers: {
                    "Authorization": 'Bearer ' + token
                }
            }).then((res) => {
                resolve(res.data);
            });
        }
    });

}

export function getVultrKeyFromArtifactID(base, artifactID) {
    let match = undefined;

    for (let i = 0; i < base.interviews.length; i++) {
        let possibleArtifacts = [
            base.note[base.interviews[i].interviewID],
            base.transcript[base.interviews[i].interviewID]
        ]

        for (let c = 0; c < possibleArtifacts.length; c++) {
            if (possibleArtifacts[c] !== undefined) {
                if (possibleArtifacts[c].artifactID === artifactID) {
                    match = possibleArtifacts[c].vultrKey;
                }
            }
        }
    }

    return match;
}

export function getSelectedInterviewWithDefaultFallback(base) {
    let interview = undefined;

    if (base.selectedInterview !== undefined) {
        for (let i = 0; i < base.interviews.length; i++) {
            if (base.interviews[i].interviewID === base.selectedInterview) {
                interview = base.interviews[i];
            }
        }
    }



    if (interview === undefined && base.interviews.length > 0) {
        interview = getFirstInterview(base.interviews, base.discoveryViewMode);
    }

    return interview;
}

export function getCurrentArtifactID(base) {
    const interviewID = base.selectedInterview;

    if (interviewID === undefined) {
        return undefined;
    }

    const artifactType = base.selectedArtifactType;

    if (artifactType === 'note' || artifactType === 'notes') {
        if (base.note[interviewID] !== undefined) {
            return base.note[interviewID].artifactID;
        }
    }

    if (artifactType === 'transcript' || artifactType === 'transcription') {
        if (base.transcript[interviewID] !== undefined) {
            return base.transcript[interviewID].artifactID;
        }
    }

    // try to return the note in this case anyways, assuming the tab hasn't been clicked
    try {
        return base.note[interviewID].artifactID;
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}

export function recieveCanvasDetails(session, set, base, token, payload) {
    set((BASE) => {
        let buffer = {
            ...base
        }

        protectedApiCall(session, 'canvas/description', {
            canvasID: payload.canvasID,
            description: payload.description
        }, {}, 'put').then((r) => {

        });

        buffer.canvas.description = payload.description;

        return buffer;
    });


}

export function recieveCustomCategory(set, base, payload) {
    // async send it off for live-updates, retention on refresh
    axios.post(`${process.env.NEXT_PUBLIC_LINX_URL}/categories`, {}, {
        params: { ...payload, userID: base.user.userID, teamID: base.team.groupID }
    });

    set((base) => {
        let buffer = {
            ...base
        }

        buffer.customFindingTypes = [
            ...buffer.customFindingTypes,
            payload
        ]

        //console.log(buffer);

        return buffer;
    })
}

export function updateCustomCategory(set, base, findingObject, changes) {
    axios.put(`${process.env.NEXT_PUBLIC_LINX_URL}/categories`, {}, {
        params: {
            // customFindingTypeID: '',
            // label: '',
            // state: '',
            // options: '',
            // parentCustomFindingTypeID: ''
            ...findingObject,
            ...changes
        }
    })

    set((base) => {
        let buffer = {
            ...base
        }
        buffer.customFindingTypes = buffer.customFindingTypes.map(f => {
            if (f.customFindingTypeID === findingObject.customFindingTypeID) {
                console.log(`Category ${f.label} updated`, { ...f, ...changes })
                return {
                    ...f,
                    ...changes,
                }
            }
            return f
        })

        return buffer
    })
}

export function setTeamCanvasLayoutID(set, layoutID) {
    set((base) => {
        let buffer = {
            ...base
        }

        buffer.team.canvasLayoutID = layoutID;

        return buffer;
    })
}

export function toggleAllFavoritedVisibility(set, base) {
    //  Axios call?
    set((base) => {
        let buffer = {
            ...base
        }

        buffer.favoritedTagsVisibility = !buffer.favoritedTagsVisibility

        return buffer
    })
}

export function findAllRolesForAParticularUser(base, userID) {
    let roles = [];

    const keys = Object.keys(base.roles);

    for (let i = 0; i < keys.length; i++) {
        const check = base.roles[keys[i]];

        if (check.userID === userID) {
            roles.push(base.roles[i]);
        }
    }

    return roles;
}

export function checkRole(base, roleName) {
    try {
        return base.roleSnapshot[roleName];
    }
    catch (e) {
        return false;
    }
}

function persistCanvasOrderChange(session, originalSet, newSet, setBase) {
    let newOrderSet = [];

    for (let i = 0; i < newSet.length; i++) {
        newOrderSet.push({
            entryID: newSet[i].entryID,
            sortOrder: newSet[i].sortOrder
        })
    }

    protectedApiCall(session, '/bulk/entry/reorder', {
        entries: newOrderSet
    }, undefined, 'put').then((r) => {
        console.log(r);
    })
}

export function reorderCanvasEntry({
    setBase,
    session,
    categoryID,
    entryID,
    newLocation,
    originalLocation,
    caytegoryEntries,
}) {
    setBase((base) => {
        let buffer = {
            ...base,
        };
        const originalOrderedEntries = sortEntries(caytegoryEntries);
        let currentOrderedEntries = clone(originalOrderedEntries);
        const [reorderedEntry] = currentOrderedEntries.splice(originalLocation, 1);

        currentOrderedEntries.splice(newLocation, 0, reorderedEntry);
        currentOrderedEntries = currentOrderedEntries.map((entry, index) => ({
            ...entry,
            sortOrder: index + 1,
        }));

        buffer.canvas.categories = buffer.canvas.categories.map((category) => ({
            ...category,
            entries:
                category.id === categoryID
                    ? currentOrderedEntries
                    : category.entries,
        }));

        persistCanvasOrderChange(
            session,
            originalOrderedEntries,
            currentOrderedEntries,
            setBase
        );

        return buffer;
    });
}

export function deleteCustomCategory(set, base, id) {
    axios.delete(`${process.env.NEXT_PUBLIC_LINX_URL}/categories`, {
        params: { customFindingTypeID: id }
    })

    set((base) => {
        let buffer = {
            ...base
        }
        buffer.customFindingTypes = buffer.customFindingTypes.filter(f => f.customFindingTypeID !== id)

        return buffer
    })
}

export function toggleOrgTreeItemExpanded(set, context, expanded) {
    set((base) => {
        let buffer = {
            ...base
        }

        // TODO: we may need to close the expansion of other entries when we do this
        if (context.parentID) {
            // read folders on the root level
            for (let folder of buffer.hierarchy) {
                // check for all the cohorts in it
                if (folder.group.groupID === context.parentID) {
                    for (let cohort of folder.children) {
                        // if it's there expand it
                        if (cohort.group.groupID === context.groupID) {
                            cohort.isExpanded = expanded
                            break
                        }
                    }
                    break
                }
            }
        } else {
            // check for cohorts on the root level
            for (let folder of buffer.hierarchy) {
                if (folder.group.groupID === context.groupID) {
                    folder.isExpanded = expanded
                    break
                }
            }
        }
        return buffer
    })
}
